<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{
        id ? "Editar Escala dos Motoristas" : "Cadastrar Escala dos Motoristas"
      }}
    </v-subheader>

    <v-form ref="form" v-model="valid" autocomplete="off" @submit.prevent>
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Escalas</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="3">
              <span class="primary--text">Data</span>
              <v-text-field
                v-model="dados.data"
                placeholder="Informe a data"
                class="mt-n1"
                v-mask-date.br
                :rules="fieldRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Hora</span>
              <v-text-field
                v-model="dados.hora"
                placeholder="Informe a hora"
                class="mt-n1"
                v-mask="'00:00'"
                :rules="fieldRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Ônibus *</span>
              <v-autocomplete
                v-model="dados.id_bus"
                :rules="fieldRules"
                :items="bus"
                item-text="descricao"
                item-value="id"
                required
                placeholder="Informe o ônibus"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <span class="primary--text">Viagem *</span>
              <v-autocomplete
                v-model="dados.id_viagem"
                :rules="fieldRules"
                :items="viagens"
                item-text="descricao"
                item-value="id"
                required
                placeholder="Informe a viagem"
                class="mt-1 custom-solo-selection"
                solo
              >
                <template slot="selection" slot-scope="{ item }"
                  >{{ item.horario }} - {{ item.descricao }}</template
                >
                <template slot="item" slot-scope="{ item }"
                  >{{ item.horario }} - {{ item.descricao }}</template
                >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Motorista *</span>
              <v-autocomplete
                v-model="dados.id_motorista"
                :rules="fieldRules"
                :items="motoristas"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe o motorista"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Origem (Trecho Inicial) *</span>
              <v-autocomplete
                v-model="dados.origem"
                :rules="fieldRules"
                :items="localidades"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe a origem (trecho inicial)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Destino (Trecho Final) *</span>
              <v-autocomplete
                v-model="dados.destino"
                :rules="fieldRules"
                :items="localidades"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe o destino (trecho final)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12">
              <span class="primary--text">Observações</span>
              <v-textarea
                v-model="dados.observacoes"
                placeholder="Informe ulgumas observações"
                class="mt-n1"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      menu: false,
      menu1: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        data: "",
        id_bus: "",
      },
      motoristas: [],
      bus: [],
      viagens: [],
      localidades: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    this.loader("motoristas");
    this.loader("bus");
    this.loader("viagens");
    this.loader("localidades");
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/escalas/motoristas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.dados.dias
            ? (this.dados.dias = JSON.parse(this.dados.dias))
            : [];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    loader(table) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/` + table)
        .then((r) => {
          table == "motoristas"
            ? (this.motoristas = r.data)
            : table == "bus"
            ? (this.bus = r.data)
            : table == "viagens"
            ? (this.viagens = r.data)
            : table == "localidades"
            ? (this.localidades = r.data)
            : [];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/escalas/motoristas/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/escalas/motoristas"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.dados.dias = JSON.stringify(this.dados.dias);
        this.$http
          .post("/escalas/motoristas/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/escalas/motoristas");
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
